import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import StickyFooter from './StickyFooter';
import Events from './Events';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.classes = makeStyles({
      root: {
        flexGrow: 1,
      }
    });  
  
  }
  render() {
    return (
      <Grid
      container
      justify="center"
      align="center"
      spacing={2}
      >       
      <Events></Events>
      <StickyFooter style={{height: '50px'}}/>

    </Grid>
    );
  }
}

export default Welcome;
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const handleGoToURL = () => 
  {
    var newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = props.URL;
  };
  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.OnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Leaving the site"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to go to: {props.URL}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.OnClose} color="primary">
            Go back
          </Button>
          <Button onClick={handleGoToURL} color="primary" autoFocus>
            Lets go!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

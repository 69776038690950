import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions  from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import Moment from 'react-moment';
import { RRule} from 'rrule'
import { Button } from '@material-ui/core';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.classes = makeStyles({
      root: {
        maxWidth: 345,
      },
      media: {
        height: 140,
      },
    });
    const rule = RRule.fromString(this.props.data.RepeatPattern);
    var startOfDay = new Date();
    startOfDay.setHours(0,0,0,0);
    this.state = {
      URL: this.props.data.EventURL,
      Date: rule.after(startOfDay, true)
    };
    
  }
  OnPress = () => {
    this.setState({DialogShown:true});
  }
  render() {  
    return (
      <TableRow 
      hover
      onClick={(e) => this.props.handleClick(this.state.URL) }
      key={this.props.key}
      >
        <TableCell>{this.props.data.Name}</TableCell>
        <TableCell><Moment format="LLLL">{this.state.Date}</Moment></TableCell>
        <TableCell>
        {this.props.data.Twitter === "" &&
          "Unknown"
        }
        {this.props.data.Twitter !== "" &&
        this.props.data.Twitter
        }
        </TableCell>
      </TableRow>
    );
  }
}

export default EventDetail;
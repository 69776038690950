import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);
    this.classes = makeStyles({
      root: {
        flexGrow: 1,
      }
    });  
  
  }
  OnBack = (event) => {
    this.props.history.push("/");
  }
  render() {
    return (
      <div 
        style={{height: 'calc(100% )'}}
        >
          <Grid
        container
        align="center"
        justify="center"
        alignContent="center"
        style={{height: '100%'}}
        >
      <Grid item style= {{ paddingBottom: '20px'}} xs={12}>
        <Typography variant="h3" display={'block'}>
          What is this?
        </Typography>
      </Grid>
      <Grid item  xs={12} sm={6}  >
        <Grid
          container
          justify="center"
          align="left"
          spacing={2}
          style={{height:'100%'}}
          >
          <Grid  item xs={12} sm={12}>
            <Typography variant="body2">
              I really miss listening to live music, then I found out about musicians live-streaming online during the lockdown.
              Then I found it difficult to easily find gigs as they came up so I decided to make this site to collate them together.
            </Typography>
            <br/>
            <Typography variant="body2">
              If you know of any musicians that are performing live and would like to let others know, please add them to the site.
            </Typography>
            <Typography variant="body2">
              To everyone doing your part during this difficult time, thank you!
            </Typography>
            <br/>
            <Typography variant="body2">
              Any suggestions or things you'd like to see, please get in touch with me via the "Questions" button below.
            </Typography>
          </Grid>
        <Grid item id="Back" xs={12} sm={12}>
              <Button variant="outlined" onClick={this.OnBack} fullWidth >
                Back?
              </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </div>
    );
  }
}

export default withRouter(About);
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid  from '@material-ui/core/Grid';
import EventDetail from "../EventDetail"
import { DB, Auth } from '../../Firebase';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LoginDialog from '../LoginDialog'
import { withRouter } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import { RRule} from 'rrule'

class AdminApprovalEvents extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.EventsCollection = DB.collection("Site/Events/Listings");
    this.state = {
      events: [],
      isLoading: true,
      hasMore: true,
      isLoggedIn: false,
      actionPending: {
        isPending : true,
        hasError : false,
        message : ""
      }
    };
  
  }
  onRetrieveInitialData = async () => {
    this.setState({
      loading: true,
    });
    let query = this.EventsCollection
    .where('Status', '==', 'Pending')
    .orderBy('StartDate')
    .limit(10);  

     let documentSnapshots = await query.get();  
     this.onCollectionUpdate(documentSnapshots);
  }

  onCollectionUpdate = (querySnapshot) => {
    const events = this.state.events;
    querySnapshot.forEach((doc) => {
      const rule = RRule.fromString(doc.data().RepeatPattern);
      var nextDate = rule.after(new Date(), true);
      events.push({
        key: doc.id,
        doc: doc.data(), 
        nextDate: nextDate
      });
    });
    this.setState({
      loading: false,
      events,
      hasMore: querySnapshot.length === 10
   });
  }

  componentDidMount() {
    Auth.onAuthStateChanged(user => {
      this.setState({isLoggedIn: user !== null});
      if(this.state.isLoggedIn === true)
      {
        this.onRetrieveInitialData();
      }
    });
  }
  populateData = () => 
  {
  };
  fetchMoreData = async () => {
    this.setState({
      loading: true,
    });

    let last = this.state.events[this.state.events.length - 1];
    let query = this.EventsCollection
    .where('Status', '==', 'Pending')
    .orderBy('StartDate')
    .startAfter(last.doc.StartDate)
    .limit(10);    
    
    let documentSnapshots = await query.get(); 
    if(documentSnapshots.empty === false)
    {
      this.onCollectionUpdate(documentSnapshots);
    } 
    else 
    {
      this.setState({
        hasMore: false
      });
    }
  };
  updateRecord = async (record, approve) => {
    this.setState({actionPending : { isPending: true}});
    let eventRef = this.EventsCollection.doc(record.key);
    if(approve)
    {
      eventRef.update({'Status': 'Approved'}).then(() => {
        this.setState({actionPending : { isPending: false }});
          this.setState({
            events: this.state.events.filter((val, _) => val !== record)
          });
      }).catch((error) => {
        this.setState({actionPending : { isPending: true, hasError: true, message: error }});
      });
    } else {
      eventRef.delete().then(() => {
        this.setState({actionPending : { isPending: false }});
        this.setState({
          events: this.state.events.filter((val, _) => val !== record)
        });
      }).catch((error) => {
        this.setState({actionPending : { isPending: true, hasError: true, message: error }});
      });
    }
  }
  OnBack = (event) => {
    this.props.history.push("/");
  }
  OnPressAdd() {

  }
  render() {
           return (
      <div>
    {this.state.isLoggedIn === true &&
    <Container component="main" >
        <Box  color="text.primary" textAlign="center" style={{height:'100%'}} m={1} p={5}>
          <Typography variant="h2" component="h1">
            To Approve
          </Typography>
        </Box>
        <Grid container spacing={1}>
        <Grid item id="Back" xs={12} sm={12}>
              <Button variant="outlined" onClick={this.OnBack} fullWidth >
                Back?
              </Button>
          </Grid>
          {this.state.events.length === 0 &&
          <Grid item id="AddAGig" xs={12} sm={12}>
            <Typography variant="h3" component="h3">
              All done!
            </Typography>
          </Grid>
          }
          <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Next Event is at</TableCell>
                  <TableCell>Posted by</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
           {this.state.events.map(event =>
              <TableRow onClick={this.OnPress}>
                <TableCell>{event.doc.Name}</TableCell>
                <TableCell><Moment calendar>{event.nextDate}</Moment></TableCell>
                <TableCell>{event.doc.Twitter}</TableCell>
                <TableCell>                 
                   <Button onClick={()=> {this.updateRecord(event, true)}}>Approve</Button>
                  <Button onClick={()=> {this.updateRecord(event, false)}}>Reject</Button>
                </TableCell>
              </TableRow>
          )} 
           </TableBody>
           </Table>
        {this.state.hasMore &&
        <Grid item id={'LoadMore'} xs={12} sm={12}>
          <Button variant="outlined" onClick={this.fetchMoreData} fullWidth >
          Load More
          </Button>
        </Grid>    
        }   
        </Grid>
    </Container>}
    {this.state.isLoggedIn === false &&
      <LoginDialog message="Please sign in (You'll need to be an admin) to approve posts"/>
    }
    </div>
    );
  }
}

export default withRouter(AdminApprovalEvents);

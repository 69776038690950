import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Welcome from './components/Welcome';
import About from './components/About';
import Events from './components/Events';
import AddEvent from './components/AddEvent';
import Container from '@material-ui/core/Container';
import AdminApprovalEvents from './components/admin/AdminApprovalEvents';
import {Privacy, TermsAndConditions} from './components/Privacy';
import "./app.css"
class App extends Component {
  render() {
   return (
    <Container component="main" style={{height: '100vh'}}>
      <div id="color-overlay"></div>
    <BrowserRouter>
      <Route exact path="/" component={Welcome} />
      <Route exact path="/About" component={About} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/event/add" component={AddEvent} />
      <Route exact path="/Admin" component={AdminApprovalEvents} />
      <Route exact path="/Privacy" component={Privacy} />
      <Route exact path="/TermsAndConditions" component={TermsAndConditions} />
    </BrowserRouter>
    </Container>
   );
  }
}

export default App;

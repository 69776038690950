import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// Firebase.
import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// Styles
import styles from './css/login.css'; // This uses CSS modules.
import './css/firebaseui-styling.global.css'; // Import globally.
import {Auth} from '../Firebase';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [isSignedIn, setSignedIn] = React.useState(false);
  const [displayName, setDisplayName] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth()
            .onAuthStateChanged(
            (user) => {
                setSignedIn(user != null)
                if(user != null)
                {
                    handleClose();
                    setDisplayName(user.displayName);
                }
            });

        // Now you either return just unregisterAuthObserver
        // which will be called when the component is unmounted
        return unregisterAuthObserver;

        // or you create a function if you want more login when the component is unmounted
        // return () => {
        //   unregisterAuthObserver();
        //   console.log("Sdd");
        // }
    }, []); // Important, pass an empty array so to execute useEffect hook only once
  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  return (    
      <div>
    {isSignedIn !== undefined && isSignedIn === false &&
    <div>
        <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        Login to add a gig
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
            <StyledFirebaseAuth className={styles.firebaseUi} uiConfig={uiConfig}
                                    firebaseAuth={Auth}/>
        </DialogContent>
        </Dialog>
    </div>
    }      
    {isSignedIn === true &&
    <div>
        <div className={styles.signedIn}>
            <Button variant="outlined" onClick={() => Auth.signOut()} fullWidth>
            Welcome back {displayName} - Logout?
            </Button>
          </div>
    </div>
    }
    </div>
  );
}
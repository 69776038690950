import * as firebase from 'firebase';
const settings = {timestampsInSnapshots: true};

export const FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "musicist.io",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(FirebaseConfig);
firebase.firestore().settings(settings);
export const DB = firebase.firestore();
export const Functions = firebase.functions();
export const Auth = firebase.auth();
export default firebase;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader  from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';




export default function StickyFooter() {
  return (
    <Grid
      container
      justify="center"
      align="center"
      spacing={2}
      style={{marginTop:'50px'}}
      >   
    <Grid item xs={6} sm={6}> 
      <List component="nav" aria-label="main" subheader={        
      <ListSubheader component="div" id="nested-list-subheader" align="left">
        Donate to musicians
      </ListSubheader>
      }>
      <ListItem button component="a" rel="noopener" href="https://www.helpmusicians.org.uk/support-our-work/make-a-donation">
        <ListItemText primary="HelpMusicians.org.uk" />
      </ListItem>
      <Divider />
      <ListItem button component="a" rel="noopener" href="https://www.sweetrelief.org/">
        <ListItemText primary="SweetRelief.org" />
      </ListItem>
      </List>
    </Grid>   
    <Grid item  xs={6} sm={6}> 
    <List component="nav" aria-label="main" subheader={
        <ListSubheader component="div" id="nested-list-subheader" align="left">
        Extra Info
        </ListSubheader>
      }>
        
        <ListItem button component="a" rel="noopener" href="/About">
          <ListItemText primary="About Musicist" />
        </ListItem>
        <Divider />
        <ListItem button component="a" rel="noopener" href="https://twitter.com/DGoodayle">
          <ListItemText primary="Who made this" />
        </ListItem>
      </List>
      </Grid>
    </Grid>
    );
}
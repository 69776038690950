import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid  from '@material-ui/core/Grid';
import EventDetail from "./EventDetail"
import { DB, Auth } from '../Firebase';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddAGigButton from './AddAGigButton'
import { withRouter } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AlertDialog from './AlertDialog'

import { RRule} from 'rrule'
import moment from 'moment';

class Events extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.EventsCollection = DB.collection("Site/Events/Listings");
    this.state = {
      events: [],
      isLoading: true,
      hasMore: true,
      isLoggedIn: false,
      DialogShown: false,
      DialogURL: ""
    };
  
  }
  onRetrieveInitialData = async () => {
    this.setState({
      loading: true,
    });
    let query = this.EventsCollection
    .where('EndDate', '>=',  new Date())
    .where('Status', '==', 'Approved')
    .orderBy('EndDate')
    .limit(20);  

     let documentSnapshots = await query.get();  
     this.onCollectionUpdate(documentSnapshots);
  }

  onCollectionUpdate = (querySnapshot) => {
    const events = this.state.events;
    querySnapshot.forEach((doc) => {
      events.push({
        key: doc.id,
        doc: doc.data(), 
      });
    });
    events.sort( (x, y) => {
      const xRule = RRule.fromString(x.doc.RepeatPattern);
      var startOfDay = new Date();
      startOfDay.setHours(0,0,0,0);
      const yRule = RRule.fromString(y.doc.RepeatPattern);
      return moment(xRule.after(startOfDay, true)).diff(moment(yRule.after(startOfDay, true)), 'days');
    });
    this.setState({
      loading: false,
      events,
      hasMore: querySnapshot.size === 10
   });
  }

  componentDidMount() {
    this.onRetrieveInitialData();
    Auth.onAuthStateChanged(user => {
      this.setState({isLoggedIn: user !== null});
    });
  }
  populateData = () => 
  {
  };
  fetchMoreData = async () => {
    this.setState({
      loading: true,
    });

    let last = this.state.events[this.state.events.length - 1];
    let query = this.EventsCollection
    .where('EndDate', '>=',  new Date())
    .where('Status', '==', 'Approved')   
    .orderBy('EndDate')
    .startAfter(last.doc.StartDate)
    .limit(20);    
    
    let documentSnapshots = await query.get(); 
    if(documentSnapshots.empty === false)
    {
      this.onCollectionUpdate(documentSnapshots);
    } 
    else 
    {
      this.setState({
        hasMore: false
      });
    }
  };
  OnExited() {
    this.setState({
      DialogShown: false
    });
  }
  OnEventPressed(URL) {
    this.setState({
      DialogShown: true,
      DialogURL: URL
    });
  }
  render() {
    return (
    <Container component="main" >
        <Box  color="text.primary" textAlign="center" pt={5} pb={5}>
          <Typography variant="h3" component="h3">
            Musicians Playing Online
          </Typography>
          <Typography variant="h6" component="h6">
            Events we know about
          </Typography>
        </Box>
        <Grid container spacing={1}>
       
         
          <Grid item xs={12} sm={12}>          
            <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Who</TableCell>
                  <TableCell>Next Streaming</TableCell>
                  <TableCell>Posted by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {this.state.events.map(event =>
                <EventDetail 
                  data={event.doc}
                  handleClick={(url) => this.OnEventPressed(url)}
                />
              )} 
              {this.state.events.length === 0 &&
          <>
           <TableRow>
              <TableCell align="center" colSpan={3}>
                At the moment we haven't got listings, help us find streams and add them below
              </TableCell>
            </TableRow>
          </>}
              </TableBody>
            </Table>
            </TableContainer>
          </Grid>
           <Grid item id="AddAGig" xs={12} sm={12}>
        <AlertDialog URL={this.state.DialogURL} OnClose={() => this.OnExited()} Open={this.state.DialogShown}/>
               <AddAGigButton Message={"Tell us about a stream?"}/>
           </Grid>
        {this.state.hasMore &&
        <Grid item id={'LoadMore'} xs={12} sm={12}>
          <Button variant="outlined" onClick={this.fetchMoreData} fullWidth >
          Load More
          </Button>
        </Grid>    
        }   
        </Grid>
    </Container>
    );
  }
}

export default withRouter(Events);

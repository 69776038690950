import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import AddEvent from './AddEvent'
import LoginDialog from './LoginDialog'
import Box from '@material-ui/core/Box';
import { Auth } from '../Firebase';

class AddAGigButton extends Component {
  constructor(props) {
    super(props);
     this.state = {
      isLoggedIn: false
    };
  
  }
  componentDidMount() {
    Auth.onAuthStateChanged(user => {
      this.setState({isLoggedIn: user !== null});
    });
  }
  render() {
    //const isLoggedIn = this.state.isLoggedIn;
    if(true)
    {
        return <div>
        <AddEvent />
        </div>
    }
    else 
    {
        return <div>
          <Typography variant="body2" component="p">
            {this.props.Message}
          </Typography>
          <Box p={1}/>
          <LoginDialog />
        </div>
    }
  }
}

export default AddAGigButton;
import 'date-fns';
import React, { Component } from 'react';
import {  Typography, FormControl, Grid,  Button } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Functions} from '../Firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { RRule} from 'rrule'
import Moment from 'react-moment';

class AddEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EventName: "",
            TwitterAccount: "",
            URL: "",
            Description: "",
            StartDate: new Date(),
            Open: false,
            ErrorMessage : "",
            Pending : false,
            HasError : false,
            ShowThankYou: false,
            Repeating: [
                { id: 'Mon', Rule:RRule.MO, selected:false},
                { id: 'Tue', Rule:RRule.TU, selected:false},
                { id: 'Wed', Rule:RRule.WE, selected:false},
                { id: 'Thu', Rule:RRule.TH, selected:false},
                { id: 'Fri', Rule:RRule.FR, selected:false},
                { id: 'Sat', Rule:RRule.SA, selected:false},
                { id: 'Sun', Rule:RRule.SU, selected:false},
            ]
        };
    }
    OnEventNameChanged = (e) => {
        this.setState({EventName: e.target.value});
    }
    OnDescriptionChanged = (e) => {
        this.setState({Description: e.target.value});
    }
    OnTwitterAccountChanged = (e) => {
        this.setState({TwitterAccount: e.target.value});
    }
    OnStartDateChanged = (e) => {
        this.setState(
            {
                StartDate: e
            });
    }
    OnAddressChanged = (e) => {
        this.setState({URL: e.target.value});
    }
    handleClickOpen = () => {
        this.setState({Open:true});
    };
    handleClose = () => {
        this.setState({Open:false});
    };
    handleErrorClose = () => {
        this.setState({HasError:false, ShowThankYou:false});
    };
    OnSubmit = async () => 
    {
        this.handleClose();
        this.setState({Pending: true});
        var weekdayRule = [];
        for (let index = 0; index < this.state.Repeating.length; index++) {
            const element = this.state.Repeating[index];
            if(element.selected)
            {
                weekdayRule.push(element.Rule);
            }
        }
        const rule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: weekdayRule,
            dtstart: this.state.StartDate
        })
        let submissionData = {              
            "EventName" : this.state.EventName,
            "Description" : this.state.Description,
            "TwitterAccount" : this.state.TwitterAccount,
            "StartDate" : this.state.StartDate.toJSON(),
            "EventURL" : this.state.URL,
            "RepeatPattern" : rule.toString()
        };
        console.log(submissionData);
        var CreateEvent = Functions.httpsCallable('CreateEvent');
        await CreateEvent(submissionData).then(result => {
            if(result.data.success)
            {
                this.setState({Pending: false, ShowThankYou:true});
            }
        }).catch(error => {
            this.setState({
                Pending: false,
                HasError : true,
                ErrorMessage: error.message
            })
        });
    }

    handleChange = (event) => {
        const Repeating = this.state.Repeating;
        var foundId = Repeating.findIndex( (x) => {
            if(x.id === event.target.name)
            {
                return true;
            }
           return false; 
        });
        Repeating[foundId].selected = event.target.checked;
        this.setState(
            Repeating
        );
    };
  
    render() {  
        const EventName = this.state.EventName;
        const TwitterAccount = this.state.TwitterAccount;
        const Description = this.state.Description;
        const StartDate = this.state.StartDate;
        const URL = this.state.URL;
        
        return (
            <div >
            {this.state.Open === true &&
        <Dialog fullWidth={true}  maxWidth={'md'} open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <ValidatorForm
                ref="form"
                onSubmit={this.OnSubmit}
                onError={errors => console.log(errors)}
            >
        <DialogContent >
       
            <Grid
            container
            align="left"
            justify="center"
            spacing={1}
            >
            {this.state.HasError &&
                <Grid item xs={12}>
                <Alert variant="outlined" severity="error">{this.state.ErrorMessage}</Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                <TextValidator
                        id="component-outlined" 
                        value={EventName}
                        onChange={this.OnEventNameChanged} 
                        label="Who is streaming?"
                        validators={['required']}
                        inputProps={{maxLength:100}}
                        variant="outlined"
                        errorMessages={['This field is required']}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                <TextValidator
                        id="component-outlined-URL" 
                        value={URL}
                        onChange={this.OnAddressChanged} 
                        label="Where are they streaming?"
                        validators={['required', 'matchRegexp:^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$']}
                        variant="outlined"
                        errorMessages={['This field is required', 'Please enter a valid URL']}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                <TextValidator
                        id="component-outlined-Description" 
                        value={Description}
                        onChange={this.OnDescriptionChanged} 
                        inputProps={{maxLength:100}}
                        label="What are they streaming?"
                        validators={['required']}
                        variant="outlined"
                        errorMessages={['This field is required']}
                        rows={2}
                        multiline={true}
                    />
                  
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={StartDate}
                    onChange={this.OnStartDateChanged}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    fullWidth={true}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Start Time (In your local time)"
                    value={StartDate}
                    onChange={this.OnStartDateChanged}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    fullWidth={true}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" display={'block'}>
                    Streaming Schedule
                </Typography>
                <FormGroup aria-label="position" row >
                    
          {this.state.Repeating.map(item => (
                <FormControlLabel
                value={item.selected}
                control={<Checkbox name={item.id} color="primary"  checked={item.selected} onChange={this.handleChange}/>}
                label={item.id}
                key={item.id}
                />
            ))}
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth={true}>
                <TextValidator
                        id="component-outlined" 
                        value={TwitterAccount}
                        onChange={this.OnTwitterAccountChanged} 
                        label="Want to share your twitter account? (Eg. @DGoodayle) - Not required"
                        validators={['matchRegexp:@([A-Za-z0-9_]{1,15})']}
                        errorMessages={['Please enter a valid twitter handle']}
                        inputProps={{maxLength:100}}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" display={'block'}>
                    To avoid spam, this event will expire after 1 month. Come back and suggest it again if you'd like!
                </Typography>
            </Grid>
        </Grid>
        
        </DialogContent>
        <DialogActions>
        <Button type="submit" variant="outlined" fullWidth={true} color="primary">Suggest the stream</Button>
        
        </DialogActions>
        </ValidatorForm>
        </Dialog>
        
        }
        {this.state.Open === false &&
                <Button variant="outlined" color="primary"  onClick={this.handleClickOpen} fullWidth>
                    Tell us about a stream
                </Button>
        }
        <Backdrop open={this.state.Pending || this.state.HasError}>
            {this.state.HasError === false && <CircularProgress color="inherit" />}
            {this.state.HasError === true &&
                <Dialog open={true} onClose={this.handleErrorClose} >
                <DialogContent>
                    <h3>
                    {this.state.ErrorMessage}
                    </h3>
                </DialogContent>
                </Dialog>
            }
            {this.state.ShowThankYou === true &&
                <Dialog open={true} onClose={this.handleErrorClose} >
                <DialogContent>
            <Grid
            container
            align="left"
            justify="center"
            spacing={1}
            >
                <Grid
                    item xs={12}>
                    <Typography variant="h4" display={'block'}>
                    Thank you!
                    </Typography>
                    <Typography variant="body2" display={'block'}>
                    I'll approve it soon (ping me a message on Twitter if you want)
                    </Typography>
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                </DialogActions>
                </Dialog>
            }
        </Backdrop>
        </div>
        );
    }
}

export default AddEvent;